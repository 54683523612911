import React from 'react';
import { useNavigate } from 'react-router-dom';

import { INotification, useNotifications } from '../../../../../common/context/NotificationsContext';
import './custom-notification-item.scss';
import { ReactComponent as VerticalLine } from 'assets/icons/vertical-line.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface NotificationItemProps {
  notification: INotification;
  isLastNotification?: boolean;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ isLastNotification = false, notification }) => {
  const { removeNotification, setSelectedNotification } = useNotifications();
  const navigate = useNavigate();

  const { id, licensePlate, time, message, type, trafficLogId } = notification;

  const handleDetailsClick = () => {
    setSelectedNotification({ trafficLogId: trafficLogId || null, licensePlate });
    removeNotification(id);
    navigate('/eiq-secure/traffic');
  };

  const closeNotification = () => {
    removeNotification(id);
  };

  const isCloseButtonShow = type === 'error' && !isLastNotification;
  const isDetailsButtonShow = type === 'success' && !isLastNotification;

  return (
    <div className={`custom-notification ${type} ${isLastNotification ? 'last-notification' : ''}`}>
      <div className="icons">
        <VerticalLine />

        {notification.type === 'success' ? <SuccessIcon /> : <ExclamationIcon />}
      </div>
      <div className="main">
        <div className="body">
          <div className="notification">{message}</div>
          {isCloseButtonShow && (
            <div className="close-btn" onClick={closeNotification}>
              <CloseIcon />
            </div>
          )}
        </div>
        {isDetailsButtonShow && (
          <div className="footer notification">
            <p className="time">{time}</p>
            <span className="details" onClick={handleDetailsClick}>
              Details
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationItem;
